<template>
  <tr :id="rowId" v-if="!!item">
    <td v-if="companies">
      <router-link :to="{ name: 'companies.pricing', params: { company: item.id } }" class="link">
        {{ item.content }}
      </router-link>
    </td>
    <td v-else class="pre-wrap">{{ item.content }}</td>
    <td>
      <modal-btn btn-class="btn-sm btn-primary min-w-75px mb-1" :target="`#${editModalId}`">
        تعديل
      </modal-btn>
      <modal-btn btn-class="btn-sm btn-soft-danger min-w-75px mb-1" :target="`#${deleteModalId}`">
        حذف
      </modal-btn>
    </td>
    <teleport to="#app">
      <confirm-model
        :target-id="editModalId"
        :title="updateText"
        confirm-text="تعديل"
        @confirmed="clickById(`submit-item${item.id}`)"
      >
        <form @submit.prevent="update">
          <FormTextArea
            name="content"
            :id="`content${item.id}`"
            :value="item.content"
          />
          <button hidden :id="`submit-item${item.id}`"></button>
        </form>
      </confirm-model>
      <items-destroyer
        :target-id="deleteModalId"
        :url="itemUrl"
        :row-id="rowId"
        @deleted="deleted"
      >
        <span>هل أنت متأكد من</span>
        <span>{{ deleteText }}</span> ؟
      </items-destroyer>
    </teleport>
  </tr>
</template>

<script>
import ModalBtn from "@/components/UI/ModalBtn";
import ItemsDestroyer from "@/components/Common/ItemsDestroyer";
import ConfirmModel from "@/components/UI/ConfirmModel";
import FormTextArea from "@/components/Forms/FormTextArea";
import Storage from "@/common/Storage";

export default {
  components: { FormTextArea, ConfirmModel, ItemsDestroyer, ModalBtn },
  emits: ["deleted", "updated"],
  props: {
    item: { required: true },
  },
  inject: ["text", "type"],
  data() {
    return {};
  },
  computed: {
    updateText() {
      if (!this.text) return "";
      return `تعديل ${this.text}`;
    },
    deleteText() {
      if (!this.text) return "";
      return `حذف ${this.text}`;
    },
    rowId() {
      return `item-row${this.item.id}`;
    },
    editModalId() {
      return `edit-item${this.item.id}`;
    },
    deleteModalId() {
      return `delete-item${this.item.id}`;
    },
    itemUrl() {
      return this.type + "/" + this.item.id;
    },
    companies() {
      return this.$route.name === "companies";
    },
  },
  methods: {
    async update(e) {
      http.inputsId = this.item.id;
      const formData = new FormData(e.target);
      formData.set("_method", "PUT");
      const response = await http.send(this.itemUrl, formData);
      if (http.responseAccepted(response)) {
        await this.$emit("updated", response.data.item);
        this.hideModal(`#${this.editModalId}`);
        http.popupMessage("success", messages.updated);
      }
      http.inputsId = "";
    },
    deleted(id) {
      Storage.remove(this.type);
      Storage.remove("admin-counters");
      this.$emit("deleted", id);
    },
  },
};
</script>
