<template>
  <div>
    <div class="w-100 text-right">
      <modal-btn btn-class="btn-sm btn-soft-danger space-x-2" target="#add-item">
        <span>إضافة</span>
        <i class="fa fa-plus"></i>
      </modal-btn>
    </div>
    <div class="table-responsive shadow">
      <table class="table table-bordered mb-0">
        <tr class="bg-bb">
          <th>{{ content }}</th>
          <th>تعديل</th>
        </tr>
        <DataItem
          v-for="item in list"
          :item="item"
          :key="item.id"
          :type="type"
          @deleted="deleted"
          @updated="updated"
        />
        <tr v-if="emptyList">
          <td colspan="2">لا يوجد</td>
        </tr>
      </table>

      <teleport to="#app">
        <confirm-model
          :title="addText"
          target-id="add-item"
          confirm-text="إضافة"
          @confirmed="clickById('submit-add-item')"
        >
          <form @submit.prevent="store">
            <form-text-area
              :placeholder="addText"
              id="content"
            ></form-text-area>
            <button hidden id="submit-add-item"></button>
          </form>
        </confirm-model>
      </teleport>
    </div>
    <ThePaginator
      :total="total"
      :url="defaultUrl"
      :last-page="lastPage"
      :links="links"
      @link-changed="setLink"
    />
  </div>
</template>

<script>
import ModalBtn from "@/components/UI/ModalBtn";
import ConfirmModel from "@/components/UI/ConfirmModel";
import FormTextArea from "@/components/Forms/FormTextArea";
import DataItem from "@/components/Pages/AuctionsLists/ListItem";
import Storage from "@/common/Storage";
import arrays from "@/common/arrays";
import ThePaginator from "@/components/UI/ThePaginator";
import pagination from "@/mixins/pagination";

export default {
  components: { ThePaginator, DataItem, FormTextArea, ConfirmModel, ModalBtn },
  mixins: [pagination],
  provide() {
    return {
      text: this.text,
      type: this.type,
    };
  },
  watch: {
    async type(type) {
      if (["notes", "companies"].includes(type)) await this.setList();
    },
  },
  computed: {
    type() {
      return this.$route.name;
    },
    defaultUrl() {
      return this.$route.name;
    },
    storageName() {
      return this.defaultUrl;
    },
    text() {
      switch (this.type) {
        case "notes":
          return "ملاحظة";
        case "companies":
          return "شركة";
        default:
          return "";
      }
    },
    content() {
      return !this.text ? "المحتوى" : "ال" + this.text;
    },
    addText() {
      return !!this.text ? `إضافة ${this.text}` : "";
    },
  },
  methods: {
    async store(e) {
      const formData = new FormData(e.target);

      if (!formData.get("content"))
        return http.popupMessage("error", messages.checkFields);

      const response = await http.send(this.type, formData);
      if (http.responseAccepted(response, "data")) {
        Storage.remove("admin-counters");
        this.hideModal("#add-item");
        this.emptyField("#content");
        http.popupMessage("success", messages.saved);
        this.setPagination(response.data);
      }
    },
  },
  async created() {
    this.storage = true;
    await this.setList();
  },
};
</script>
